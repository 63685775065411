import { createSelector } from 'reselect';

export const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    user => user.currentUser
)

export const selectTempUser = createSelector(
  [selectUser],
  user => user.tempUser 
)

export const selectIsUserLoggedIn = createSelector(
    [selectUser],
    user => user.isLoggedIn
)

export const selectIsUserFetching = createSelector(
    [selectUser],
    user => user.isUserFetching
)

export const selectLeaderStatus = createSelector(
  [selectCurrentUser],
  currentUser => currentUser && currentUser.leaderStatus.trim().toLowerCase()
)

//selects currentUser(leProfile) and retuns currentCommisionPercentange for current Month
export const selectCurrentMonthCommission = createSelector(
    [selectCurrentUser],
    currentUser => currentUser && currentUser.currentMonthCommission
  );

//selects currentUser(leProfile) and retuns nextCommisionPercentange for next Month
export const selectNextMonthCommission = createSelector(
    [selectCurrentUser],
    currentUser => currentUser && currentUser.nextMonthCommission
  );

//selects currentUser(leProfile) and retuns currentCommisionPercentange for current Month
export const selectCommissionCurrentYear = createSelector(
  [selectCurrentUser],
  currentUser => currentUser && currentUser.currentMonthCommissionData
);

//selects currentUser(leProfile) and retuns nextCommisionPercentange for next Month
export const selectCommissionNextYear = createSelector(
  [selectCurrentUser],
  currentUser => currentUser && currentUser.nextMonthCommissionData
);

  //select le written off percentage i.e. WriteOffPercent column from le_profiles

export const selectLeWrittenOffPct = createSelector(
    [selectCurrentUser],
    currentUser => currentUser.writtenOffPct
  );
  
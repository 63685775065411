import { customerActionTypes } from "./customer.types";

const INITIAL_STATE = {
    custActivityExt: null,
    isCustActivityExtFetching: false,
    iscustActivityFetchingError: undefined
}

const customerActivityExt = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case customerActionTypes.FETCH_CUSTOMEREXT_START:
      return {
        ...state,
        isCustActivityExtFetching: true,
      };
    case customerActionTypes.FETCH_CUSTOMEREXT_SUCCESS:
      return {
        ...state,
        isCustActivityExtFetching: false,
        custActivityExt: action.payload,
      };
    case customerActionTypes.FETCH_CUSTOMEREXT_FAILED:
      return {
        ...state,
        isCustActivityExtFetching: false,
        iscustActivityFetchingError: action.payload,
      };

    default:
      return state;
  }
};


export default customerActivityExt;
import { modalActionType } from './modal.action';

const initialState = {
    showModal : false,
    modalContent  : null,
    actionMode : null,
    actionFunc: null,
}


const modalReducer = (state=initialState, action) => {
    switch (action.type) {
        case modalActionType.OPEN_MODAL:
        return {
            ...state,
            showModal: true,
            modalContent: action.payload.modalContent,
            actionMode: action.payload.actionMode,
            actionFunc: action.payload.actionFunc
        }
        case modalActionType.CLOSE_MODAL:
            return {
                ...state,
                showModal: false,
            }
        default:
            return state;
    }
}


export default modalReducer;
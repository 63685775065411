import { leadsActionType } from "./leads.type";

const initialState = {
  isLeadsFetching: false,
  leads: null,
  mode: null,
  leadData: null,
  errorMessage: null,
  assignedLeads: null,
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case leadsActionType.FETCH_LEAD_START:
      return {
        ...state,
        isLeadsFetching: true,
      };
    case leadsActionType.FETCH_LEAD_SUCCESS:
      return {
        ...state,
        leads: action.payload,
        isLeadsFetching: false,
      };
    case leadsActionType.FETCH_ASSIGNED_LEAD_START:
      return {
        ...state,
        isLeadsFetching: true,
      };
    case leadsActionType.FETCH_ASSIGNED_LEAD_SUCCESS:
      return {
        ...state,
        assignedLeads: action.payload,
        isLeadsFetching: false,
      };
    case leadsActionType.FETCH_LEAD_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isLeadsFetching: false,
      };
    case leadsActionType.SET_LEAD_ACTION_MODE:
      return {
        ...state,
        mode: action.payload.mode,
        leadData: action.payload.lead,
      };
    case leadsActionType.RESET_ASSIGNED_LEADS:
      return {
        ...state,
        assignedLeads: null,
      };
    case leadsActionType.RESET_LEADS:
      return {
        ...state,
        leads: null,
      };
    default: {
      return state;
    }
  }
};

export default leadsReducer;

import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";

import { PublicRoute, PrivateRoute, RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Dashboard as DashboardLayout,
} from "../layouts";

import Spinner from "components/spinner/spinner";
import ErrorBoundry from "components/error-boundry/error-boundry";

const DashboardPage = lazy(() => import("pages/dashboard/dashboard.page"));
const SignInPage = lazy(() => import("pages/sign-in/sign-in-page"));
const RegisterPage = lazy(() => import("pages/register/register.page"));
const CustomerPreviewPage = lazy(() =>
  import("pages/customer-preview/customer-preview.page")
);
const CustomerDetailPage = lazy(() =>
  import("pages/customer-detail/customer-detail-page")
);
const LeadsPage = lazy(() => import("pages/leads/leads.page"));
const AssignedLeadsPage = lazy(() => import("pages/leads/assigned.page"));
const TrainingAndSalesAidsPage = lazy(() =>
  import("pages/training_and_sales-aids/training_and_sales-aids.page")
);
const VideoProjectPage = lazy(() => import("pages/video-project"));
const AudioResourcePage = lazy(() => import("pages/audio-resource"));
const MyAccountPage = lazy(() => import("pages/my-account/my-account.page"));
const OrderFormPage = lazy(() => import("pages/order-form/order-form.page"));
const DownPaymentPage = lazy(() =>
  import("pages/down-payment/down-payment.page")
);
const ResetPasswordPage = lazy(() =>
  import("pages/reset-password/reset-password")
);
const ContractPage = lazy(() => import("pages/econtract-form"));
const Reciept = lazy(() => import("pages/reciept"));
const HistoryPage = lazy(() => import("pages/econtract-history"));

const Routes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <ErrorBoundry>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={DashboardPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path="/dashboard"
            component={DashboardPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path="/customers"
            component={CustomerPreviewPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/customer-detail"
            component={CustomerDetailPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/training-and-sales-aids"
            component={TrainingAndSalesAidsPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/year-end"
            component={VideoProjectPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/audio-resource"
            component={AudioResourcePage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/my-account"
            component={MyAccountPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/order-form"
            component={OrderFormPage}
            layout={MainLayout}
          />
          <PrivateRoute
            exact
            path="/down-payment"
            component={DownPaymentPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path="/assigned-leads"
            component={AssignedLeadsPage}
            layout={MainLayout}
          />

          <PrivateRoute
            path="/leads"
            component={LeadsPage}
            layout={MainLayout}
          />
          <PublicRoute
            exact
            path="/signin"
            component={SignInPage}
            layout={MinimalLayout}
          />
          <PublicRoute
            exact
            path="/register"
            component={RegisterPage}
            layout={MinimalLayout}
          />
          <PublicRoute
            path="/password-reset"
            component={ResetPasswordPage}
            layout={MinimalLayout}
          />
          <PrivateRoute
            path="/econtract"
            component={ContractPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path="/receipt/:id"
            component={Reciept}
            layout={MainLayout}
          />
          <PrivateRoute
            path="/econtract-history"
            component={HistoryPage}
            layout={DashboardLayout}
          />
          <Redirect to={{ pathname: "/" }} />
        </Switch>
      </ErrorBoundry>
    </Suspense>
  );
};

export default Routes;

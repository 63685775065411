import { dlActionTypes } from './dl.type';


const initialState = {
    dlProfiles: null
}

const dlReducer = (state = initialState, action) => {

    switch (action.type) {
        case dlActionTypes.SET_DL_PROFILES:
            
            return  {
                ...state,
                dlProfiles: action.payload
            }
    
        default:
            return state;
    }
}

export default dlReducer;
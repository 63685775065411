import api from "../../common/api";
import { leadsActionType } from "./leads.type";

const API_URL = api.apiUrl;

const fetchLeadsStart = () => {
  return {
    type: leadsActionType.FETCH_LEAD_START,
  };
};

const fetchLeadsSuccess = (leadData) => {
  return {
    type: leadsActionType.FETCH_LEAD_SUCCESS,
    payload: leadData,
  };
};

const fetchAssignedLeadsStart = () => {
  return {
    type: leadsActionType.FETCH_ASSIGNED_LEAD_START,
  };
};

const fetchAssignedLeadsSuccess = (leadData) => {
  return {
    type: leadsActionType.FETCH_ASSIGNED_LEAD_SUCCESS,
    payload: leadData,
  };
};

const fetchLeadsFailed = (errorMessage) => {
  return {
    type: leadsActionType.FETCH_LEAD_FAILED,
    payload: errorMessage,
  };
};

export const setLeadActionMode = (mode, lead) => {
  return {
    type: leadsActionType.SET_LEAD_ACTION_MODE,
    payload: {
      mode,
      lead,
    },
  };
};

const addLead = () => {
  return {
    type: leadsActionType.ADD_LEAD,
  };
};

const updateLead = () => {
  return {
    type: leadsActionType.UPDATE_LEAD,
  };
};

const deleteLead = () => {
  return {
    type: leadsActionType.DELETE_LEAD,
  };
};

export const resetLeads = () => {
  return { type: leadsActionType.RESET_LEADS };
};

export const resetAssignedLeads = () => {
  return { type: leadsActionType.RESET_ASSIGNED_LEADS };
};

// Async Actions

export const fetchLeadsStartAsync = (retriveLimit, confId) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      dispatch(fetchLeadsStart());
      const request = await fetch(`${API_URL}/leads`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          limit: retriveLimit,
          confId: confId,
        }),
      });
      if (request.status === 200) {
        const data = await request.json();
        dispatch(fetchLeadsSuccess(data));
      } else {
        dispatch(fetchLeadsFailed("error fetching leads data from the server"));
      }
    } catch (error) {
      if (error) {
        dispatch(fetchLeadsFailed(error.message));
        console.log(error.message);
      }
    }
  };
};

export const fetchLeadsAssignedStartAsync = (leid) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(fetchAssignedLeadsStart());
    try {
      const request = await fetch(`${API_URL}/assignedLeads/${leid}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      });
      if (request.status === 200) {
        const data = await request.json();
        dispatch(fetchAssignedLeadsSuccess(data));
      } else {
        dispatch(fetchLeadsFailed("error fetching the data"));
      }
    } catch (error) {
      dispatch(fetchLeadsFailed(error.message));
    }
  };
};

//Add Lead

export const addLeadAsync = (leadData, conf_id) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(addLead());
    try {
      const request = await fetch(`${API_URL}/lead/add`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          firstName: leadData.firstName.value,
          lastName: leadData.lastName.value,
          email: leadData.email.value,
          address1: leadData.address1.value,
          address2: leadData.address2.value,
          city: leadData.city.value,
          state: leadData.state.value,
          zip: leadData.zip.value,
          phone: leadData.phone.value,
          leadSource: leadData.leadSource.value,
          booksInterested: leadData.booksInterested.value,
          notes: leadData.notes.value,
          conf_id,
        }),
      });
      const data = await request.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

//Update Lead

export const updateLeadAsync = (leadData, lead) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(updateLead());
    try {
      const request = await fetch(`${API_URL}/lead/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          leadId: lead.leads_id,
          firstName: leadData.firstName.value,
          lastName: leadData.lastName.value,
          email: leadData.email.value,
          address1: leadData.address1.value,
          address2: leadData.address2.value,
          city: leadData.city.value,
          state: leadData.state.value,
          zip: leadData.zip.value,
          phone: leadData.phone.value,
          leadSource: leadData.leadSource.value,
          booksInterested: leadData.booksInterested.value,
          notes: leadData.notes.value,
          status: leadData.status.value,
          district: leadData.district.value,
        }),
      });
      const data = await request.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

//Delete Lead

export const deleteLeadAsync = (lead) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(deleteLead());
    try {
      const request = await fetch(`${API_URL}/lead/delete`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          leadId: lead.leads_id,
        }),
      });
      const data = await request.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

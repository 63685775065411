import { pushSubsTypes } from "./push-subs.type";
import api from "../../common/api";
import { sendPushNotification } from "../../utils/sw";

const API_URL = api.apiUrl;

const fetchPushSubsPending = () => {
  return {
    type: pushSubsTypes.FETCH_PUSH_SUBS_PENDING,
  };
};

const fetchPushSubsSuccess = (subscriptions) => {
  return {
    type: pushSubsTypes.FETCH_PUSH_SUBS_SUCCESS,
    payload: subscriptions,
  };
};

const fetchPushSubsFailed = (errMessage) => {
  return {
    type: pushSubsTypes.FETCH_PUSH_SUBS_FAILED,
    payload: errMessage,
  };
};

const sendLeadNotificationEmail = (leid) => {
  fetch(`${API_URL}/notify`, {
      method: 'post',
      headers: {
          'Content-Type': 'Application/json'
      },
      body: JSON.stringify({
          leid
      })
  })
  .then(resp => resp.ok && console.log('Succefully notified LE via Email'))
  .catch(err => console.log(err))
}

// export const deletePushSubs = async (leid) => {
//     const token = window.localStorage.getItem('token');
//     if(token) {
//         const request = await fetch(`${API_URL}/deleteSubs`, {
//             method: 'post',
//             headers: {
//                 'Content-Type' : 'applicatoin/json',
//                 'Authorization' : token
//             },
//             body: JSON.stringify({
//                 leId: leid
//             })
//         })
//         .then(resp => {
//             if(resp.ok && resp.json() === 'success') {
//                 alert('You are unsubscribed!')
//             }
//         })
//         .catch(err => console.log(err))
//     }
// }

//Async Actions

export const fetchPushSubsStartAsync = (leid) => {
//send email notificaiton
  sendLeadNotificationEmail(leid);
  const token = window.localStorage.getItem("token");
  if (token) {
    return (dispatch) => {
      dispatch(fetchPushSubsPending());
      fetch(`${API_URL}/subs/${leid}`, {
        method: "get",
        headers: {
          "Content-Type": "applicatoin/json",
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((subs) => {
          if (subs.length !== 0 && subs[0].id) {
            sendPushNotification(subs,  
              "New Lead Assigned!",
              "You have been assigned a new lead.");
            return dispatch(fetchPushSubsSuccess(subs));
          }
        })
        .catch((err) => dispatch(fetchPushSubsFailed(err.message)));
    };
  }
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import CustomButton from "../custom-button/custom-button";
import { setCurrentUser } from "../../redux/user/user.action";

// import { LE_MENU_DATA } from './menu.data';
import { onRouteChange } from "../utils/misc";

import "./menu-dropdown.styles.scss";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "redux/user/user.selector";

const MenuDropdown = ({
  toggleDropdownMenu,
  parent,
  setCurrentUser,
  tempUser,
  currentUser,
}) => {
  const history = useHistory();

  const [menuDropdownWidth, setMenuDropdownWidth] = useState(
    window.innerWidth > 500 ? "260px" : "100%"
  );
  useEffect(() => {
    const assignWidthOnWindowResize = () => {
      if (window.innerWidth > 500) {
        setMenuDropdownWidth("260px");
      } else {
        setMenuDropdownWidth("100%");
      }
    };
    window.addEventListener("resize", assignWidthOnWindowResize);
    return () =>
      window.removeEventListener("resize", assignWidthOnWindowResize);
  }, [menuDropdownWidth]);
  return (
    <div
      className="menu-dropdown"
      style={{
        left: `${parent === "menu" && "0"}`,
        top: `${parent === "menu" && "48px"}`,
        position: "absolute",
        width: menuDropdownWidth,
        padding: "20px 30px 20px 10px",
        backgroundColor: "#fff",
        top: "50px",
        zIndex: -1,
      }}
    >
      <div className="menu-items">
        {parent === "profile" && (
          <span
            className="menu-item"
            onClick={() =>
              toggleDropdownMenu("/my-account", "close", "profile")
            }
          >
            My Account
          </span>
        )}
        {parent === "e-contract" && (
          <span className="sub-menu-container">
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/econtract", "close", "profile")
              }
            >
              E-Contract
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/econtract-history", "close", "profile")
              }
            >
              E-Contract LE Portal
            </span>
          </span>
        )}

        {parent === "resources" && (
          <span className="sub-menu-container">
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/order-form", "close", "profile")
              }
            >
              Order Forms
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu(
                  "/training-and-sales-aids",
                  "close",
                  "profile"
                )
              }
            >
              Training And Sales Aids
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/year-end", "close", "profile")
              }
            >
              Video Resource
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/audio-resource", "close", "profile")
              }
            >
              Audio Resource
            </span>
          </span>
        )}

        {/* {parent === "menu" &&
          LE_MENU_DATA.map((menuData) => {
            return (
              <span
                key={menuData.id}
                className="menu-item"
                onClick={() =>
                  toggleDropdownMenu(
                    routeGenerator(menuData),
                    "close",
                    "profile"
                  )
                }
              >
                
                {menuData.menuName}
              </span>
            );
          })} */}
        {parent === "menu" && (
          <>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/dashboard", "close", "profile")
              }
            >
              Dashboard
            </span>
            {(tempUser && tempUser.LEID === "4321") ||
            tempUser.LEID === "5092" ? null : (
              <span
                className="menu-item"
                onClick={() =>
                  toggleDropdownMenu("/customers", "close", "profile")
                }
              >
                Customers
              </span>
            )}
            {currentUser.leaderStatus.toLowerCase() ===
              "publishing director" && (
              <span
                className="menu-item"
                onClick={() => toggleDropdownMenu("/leads", "close", "profile")}
              >
                Leads
              </span>
            )}

            {currentUser.leaderStatus.toLowerCase() !==
              "publishing director" && (
              <span
                className="menu-item"
                onClick={() =>
                  toggleDropdownMenu("/assigned-leads", "close", "profile")
                }
              >
                <span>Assigned Leads</span>
              </span>
            )}

            {/* <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu(
                  "https://forms.logiforms.com/formdata/user_forms/77894_7282070/357768/page1.html?cachebust=752",
                  "close",
                  "profile"
                )
              }
            >
              E-Contract
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu(
                  "https://forms.logiforms.com///published/index.lf?mode=live&workflowid=4b9f4755-5a4a-4692-afc1-0b1262645721&usr_id=77894&pageid=128424",
                  "close",
                  "profile"
                )
              }
            >
              E-Contract LE Portal
            </span> */}

            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/econtract", "close", "profile")
              }
            >
              E-Contract
            </span>
            
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/econtract-history", "close", "profile")
              }
            >
              E-Contract LE Portal
            </span>

            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/order-form", "close", "profile")
              }
            >
              Order Form
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/down-payment", "close", "profile")
              }
            >
              Down Payment
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu(
                  "/training-and-sales-aids",
                  "close",
                  "profile"
                )
              }
            >
              Training And Sales Aids
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/year-end", "close", "profile")
              }
            >
              Video Project
            </span>
            <span
              className="menu-item"
              onClick={() =>
                toggleDropdownMenu("/audio-resource", "close", "profile")
              }
            >
              Audio Resource
            </span>
          </>
        )}
      </div>
      {parent === "profile" && (
        <CustomButton
          onClick={() => {
            toggleDropdownMenu("/signin", "close", "profile");
            return onRouteChange("signout", setCurrentUser, history);
          }}
        >
          Sign Out
        </CustomButton>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuDropdown);

import { conferenceActionTypes } from "./conference.types";

const initialState = {
  lesHistorybydistrictObj: null,
  leProfilesbydistrictObj: null,
  isLesHistoryFetching: false,
  lesHistoryErrorMessage: "",
};

const conferenceReducer = (state=initialState, action) => {
  switch (action.type) {
    case conferenceActionTypes.FETCH_LES_HISTORY_START:
      return {
        ...state,
        isLesHistoryFetching: true
      }
    case conferenceActionTypes.FETCH_LES_HISTORY_SUCCESS:
      return {
        ...state,
        lesHistorybydistrictObj: action.payload,
        isLesHistoryFetching: false,
      };
    case conferenceActionTypes.FETCH_LES_HISTORY_FAILED:
      return {
        ...state,
        lesHistoryErrorMessage: action.payload,
        isLesHistoryFetching: false,
      };

      case conferenceActionTypes.TRANSFORM_LES_PROFILE_BY_DISTRICT:
        return {
          ...state,
          leProfilesbydistrictObj: action.payload,
        };

    default:
      return state;
  }
};

export default conferenceReducer;

import { NotificationManager } from "react-notifications";

const createNotification = (type, title, message, timeout=3000) => {
  switch (type) {
    case "info":
      NotificationManager.info(message, title, timeout);
      break;
    case "success":
      NotificationManager.success(message, title, timeout);
      break;
    case "warning":
      NotificationManager.warning(message, title, timeout);
      break;
    case "error":
      NotificationManager.error(message, title, timeout, () => {
        if(title === 'Denied') {
          let url = 'https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en'
          return window.location.assign(url)
        }
      });
      break;
    default:
      return;
  }
};

export default createNotification;

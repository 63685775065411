import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectIsUserLoggedIn,
  selectCurrentUser,
  selectTempUser,
  selectIsUserFetching,
} from "redux/user/user.selector";

const PublicRoute = ({
  isLoggedIn,
  currentUser,
  tempUser,
  loading,
  layout: Layout,
  component: Component,
  location,
  ...rest
}) => {
  const getRedirectRoute = () => {
    if (location.state) {
      if (location.state.from.pathname.includes("assigned-leads")) {
        return {
          ...location.state.from,
          pathname: "assigned-leads",
        };
      }
      if (location.state.from.pathname.includes("leads")) {
        return {
          ...location.state.from,
          pathname: "leads",
        };
      }
      return location.state.from;
    }

    return "/";
  };
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isLoggedIn && !loading) {
          return <Redirect to={getRedirectRoute()} />;
        }

        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsUserLoggedIn,
  currentUser: selectCurrentUser,
  tempUser: selectTempUser,
  loading: selectIsUserFetching,
});

export default connect(mapStateToProps)(PublicRoute);

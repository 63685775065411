import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import leReducer from './le/le.reducer';
import customerReducer from './customer/customer.reducer';
import custActivityExt from './customer/custActivityExt.reducer';
import orderDetailsReducer from './order/order.reducer';
import customerStatementReducer from './statement/statement.reducer';
import dlReducer from './dl/dl.reducer';
import conferenceReducer from './conference/conference.reducer';
import accountSummaryReducer from './account-summary/account-summary.reducer';
import leadsReducer from './leads/leads.reducer';
import modalReducer from './modal/modal.reducer';
import pushSubsReducer from './push-subs/push-subs.reducer';
import econtractReducer from './econtract/econtract.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  le: leReducer,
  customer: customerReducer,
  customerExt: custActivityExt,
  order: orderDetailsReducer,
  statement: customerStatementReducer,
  dl: dlReducer,
  conference: conferenceReducer,
  account: accountSummaryReducer,
  leads: leadsReducer,
  modal: modalReducer,
  subs: pushSubsReducer,
  econtract: econtractReducer
});

export default rootReducer;

import api from "../common/api";
import createNotification from "../components/react-notification/react-notification";

let API_URL = api.apiUrl;
//convert string to urlBase64Unit8Arrays
export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

//display notification with given configuration
export const displayConfirmNotification = (message) => {
  if ("serviceWorker" in navigator) {
    var options = {
      body: message.body,
      icon: "/logo192.png",
      dir: "ltr",
      lang: "en-US", // BCP 47,
      vibrate: [100, 50, 200],
      badge: "/logo192.png",
      tag: "confirm-notification",
      renotify: true,
      //   actions: [
      //     {
      //       action: "confirm",
      //       title: "Okay",
      //       icon: "/src/images/icons/app-icon-96x96.png",
      //     },
      //     {
      //       action: "cancel",
      //       title: "Cancel",
      //       icon: "/src/images/icons/app-icon-96x96.png",
      //     },
      //   ],
    };

    navigator.serviceWorker.ready.then(function (swreg) {
      swreg.showNotification(message.title, options);
    });
  }
};

export const sendPushNotification = (
  subscription,
  title,
  body
) => {
  // console.log("subscriptionData", subscription);
  const token = localStorage.getItem("token");
  if (subscription) {
    // Send Push Notification
    // console.log("Sending Push...");
    fetch(`${API_URL}/sendPush`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        subscription,
        title,
        body,
      }),
    })
      .then((resp) => resp.ok && console.log("user notified successfully"))
      .catch((err) => console.log("error sending notification", err));
  }
};


var pushNotification = function () {  

  if ('safari' in window && 'pushNotification' in window.safari) {
      alert("push notification supported")  
      // var permissionData = window.safari.pushNotification.permission(pushId);  
      // checkRemotePermission(permissionData);  
  } else {  
      alert("Push notifications not supported.");  
  }  
};  

export const configurePushSub = (userId) => {
  console.log("userId: ", userId);
  if (!("serviceWorker" in navigator)) {
    return;
  }
  // pushNotification();
  var reg;
  var token = localStorage.getItem("token");
  navigator.serviceWorker.ready
    .then(function (swreg) {
      reg = swreg;
      return swreg.pushManager.getSubscription();
    })
    .then(function (sub) {
      // console.log('subscription == ', sub)
      if (sub === null) {
        // Create a new subscription
        var vapidPublicKey =
          "BIj-Ik0FxNnxDFitKJGLlRl-02g2--xJgedntpP0Aijd6jt6zgk_Sbsf__HY5L_TNzMwUpIbI7FG6Rd6SjV4WBs";
        var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
        reg.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidPublicKey,
          })
          .then((newSub) => {
            return fetch(`${API_URL}/subscribe`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
              },
              body: JSON.stringify({
                sub: newSub,
                leId: userId,
              }),
            });
          })
          .then((resp) => resp.json())
          .then((subData) => {
            if (subData[0].id) {
              // console.log(subData);
              // console.log("Send Push Notification Of New Lead Assigned!");
              sendPushNotification(
                subData,
                "Successfully subscribed!",
                "You successfully subscribed to our Notification service!"
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //   We have a subscription stored in database,
        // console.log('alredy subscribed')
        createNotification('info', "Already Subscribed!", "You have already subscribed to our notification")

        return fetch(`${API_URL}/subs/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          },
        })
          .then((resp) => resp.json())
          .then((subs) => {
            if (subs[0].id) {
              // displayConfirmNotification();
              // console.log(`all the subs for leid: ${userId}`, subs);
              // console.log("send push notification");
              sendPushNotification(
                subs,
                "Already Subscribed!",
                "You have already subscribed to our notification"
              );
            } else {
              alert(
                `LE ${userId} couldn't be notified because push notification is turned off`
              );
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const askForNotificationPermission = (userId) => {
  Notification.requestPermission(function (result) {
    // console.log("User Choice", result);
    if (result !== "granted") {
      // console.log("No notification permission granted!");
      createNotification(
        "error",
        "Denied",
        "Please enable notification from browser, Click Here"
      );
    }
    return configurePushSub(userId);
  });
};
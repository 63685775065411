import { customerActionTypes } from './customer.types';
import { removeTrailingSpace } from '../../components/utils/misc';
import api from '../../common/api';

const API_URL = api.apiUrl;
export const fetchCustomerStart = () => {
    return {
        type: customerActionTypes.FETCH_CUSTOMER_START
    }
}

export const fetchCustomerSuccess = (customerActivity) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMER_SUCCESS,
        payload: removeTrailingSpace(customerActivity)
    }
}

export const fetchCustomerFailed = (errorMessage) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMER_FAILED,
        payload: errorMessage
    }
}

export const fetchCustomerStartAsync = (leid) => {
    const token = window.localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchCustomerStart());
        fetch(`${API_URL}/customerActivity/${leid}`, {
            method: 'get',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : token
            }
        })
        .then(resp => resp.json())
        .then(customerActivity => customerActivity[0].customernbr && dispatch(fetchCustomerSuccess(customerActivity)))
        .catch(err => dispatch(fetchCustomerFailed(err.message)))
    }
}

export const fetchCustomerExtStart = () => {
    return {
        type: customerActionTypes.FETCH_CUSTOMEREXT_START
    }
}



export const fetchCustomerExtSuccess = (customerActivityExt) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMEREXT_SUCCESS,
        payload: removeTrailingSpace(customerActivityExt)
    }
}

export const fetchCustomerExtFailed = (errorMessage) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMEREXT_FAILED,
        payload: errorMessage
    }
}


export const fetchCustomerExtStartAsync = (customernbr) => {
    const token = window.localStorage.getItem('token');
    return dispatch => {
        dispatch(fetchCustomerExtStart());
        fetch(`${API_URL}/customeractivityext/${customernbr}`, {
            method: 'get',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : token
            }
        })
        .then(resp => resp.json())
        .then('resp',console.log)
        .then(customerActivity => customerActivity && dispatch(fetchCustomerExtSuccess(customerActivity)))
        .catch(err => dispatch(fetchCustomerExtFailed(err.message)))
    }
} 


//fetch customers for given leids array

export const fetchCustomersStart = () => {
    return {
        type: customerActionTypes.FETCH_CUSTOMERS_START
    }
}

export const fetchCustomersSuccess = (customerActivity) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: removeTrailingSpace(customerActivity)
    }
}

export const fetchCustomersFailed = (errorMessage) => {
    return {
        type: customerActionTypes.FETCH_CUSTOMERS_FAILED,
        payload: errorMessage
    }
}

export const fetchCustomersOfLesFromSameDistrictStartAsync = (leids) => {
    const token = window.localStorage.getItem('token');
        return dispatch => {
            dispatch(fetchCustomersStart());
            fetch(`${API_URL}/customerActivity`, {
                method: 'post',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : token
                },
                body: JSON.stringify({
                    leids
                })
            })
            .then(resp => resp.json())
            .then(customerActivity => dispatch(fetchCustomersSuccess(customerActivity)))
            .catch(err => dispatch(fetchCustomersFailed(err.message)))
    }
}

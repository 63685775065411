import { customerActionTypes } from "./customer.types";

const INITIAL_STATE = {
  isCustomerFetching: false,
  customerActivity: null,
  errorMessage: undefined,
  isCustomersActivityFetching: false,
  customersActivityOfLesFromSameDistrict: null,
  customerActivityErrorMessage: null,
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case customerActionTypes.FETCH_CUSTOMER_START:
      return {
        ...state,
        isCustomerFetching: true,
      };
    case customerActionTypes.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCustomerFetching: false,
        customerActivity: action.payload,
      };
    case customerActionTypes.FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        isCustomerFetching: false,
        errorMessage: action.payload,
      };

      case customerActionTypes.FETCH_CUSTOMERS_START:
        return {
          ...state,
          isCustomersActivityFetching: true,
        };
      case customerActionTypes.FETCH_CUSTOMERS_SUCCESS:
        return {
          ...state,
          isCustomersActivityFetching: false,
          customersActivityOfLesFromSameDistrict: action.payload,
        };
      case customerActionTypes.FETCH_CUSTOMERS_FAILED:
        return {
          ...state,
          isCustomersActivityFetching: false,
          customerActivityErrorMessage: action.payload,
        };

    default:
      return state;
  }
};

export default customerReducer;

import { contractActionType } from "./econtract.types";

const initialState = {
  loading: false,
  contractList: [],
  errorMessage: null,
  customer: {},
  updateOrder: {},
  viewReference: {},
  orderedBooks: [],
  viewPayment: {},
  ccResponse: {},
  alert: {
    severity: "",
    status: false,
    message: "",
  },
};

const econtractReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case contractActionType.CC_POST_START:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.CC_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        ccResponse: payload,
        loading: false,
        alert: {
          severity: "success",
          status: true,
          message: "Payment Successfully Updated",
        },
      };
    case contractActionType.CC_POST_SUCCESS_STATUS:
      return {
        ...state,
        ccResponse: payload,
      };
    case contractActionType.CC_POST_FAILURE_STATUS:
      return {
        ...state,
        ccResponse: payload,
      };
    case contractActionType.CC_POST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload,
        alert: {
          severity: "error",
          status: true,
          message: payload || "Failed to Update Payment",
        },
      };

    case contractActionType.FETCH_CONTRACT_START:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        contractList: payload,
        loading: false,
      };
    case contractActionType.FETCH_CONTRACT_FAILED:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    case contractActionType.SET_CUSTOMER_INFO:
      return {
        ...state,
        customer: {
          contract: payload.contract,
          payment: payload.payment,
          orders: payload.orders,
        },
      };
    case contractActionType.RESET_CUSTOMER_INFO:
      return {
        ...state,
        customer: {},
      };
    case contractActionType.VIEW_UPDATE_ORDER_START:
      return {
        ...state,
      };
    case contractActionType.VIEW_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        updateOrder: payload,
      };
    case contractActionType.VIEW_UPDATE_ORDER_FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    case contractActionType.VIEW_REFERENCE_START:
      return {
        ...state,
      };

    case contractActionType.VIEW_REFERENCE_SUCCESS:
      return {
        ...state,
        viewReference: payload,
      };

    case contractActionType.VIEW_REFERENCE_FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    case contractActionType.VIEW_PAYMENT_START:
      return {
        ...state,
      };

    case contractActionType.VIEW_PAYMENT_SUCCESS:
      return {
        ...state,
        viewPayment: payload,
      };

    case contractActionType.VIEW_PDFLINK_FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case contractActionType.VIEW_PDFLINK_START:
      return {
        ...state,
      };

    case contractActionType.VIEW_PDFLINK_SUCCESS:
      return {
        ...state,
        viewPdfLink: payload,
      };

    case contractActionType.VIEW_PAYMENT_FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };

    case contractActionType.MARK_AS_PAID_START:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.MARK_AS_PAID_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "success",
          status: true,
          message: "Successfully Marked As Paid",
        },
      };
    case contractActionType.MARK_AS_PAID_FAILURE:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "error",
          status: true,
          message: "Successfully Marked As Paid",
        },
      };
    case contractActionType.GET_BOOK_OORDER_START:
      return {
        ...state,
      };
    case contractActionType.GET_BOOK_ORDER_SUCCESS:
      return {
        ...state,
        orderedBooks: payload,
      };
    case contractActionType.GET_BOOK_ORDER_FAILURE:
      return {
        ...state,
        errorMessage: payload,
      };
    case contractActionType.DELETE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "error",
          status: true,
          message: payload || "Failed to Delete Order",
        },
      };
    case contractActionType.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "success",
          status: true,
          message: "Successfully Deleted Order",
        },
      };
    case contractActionType.DELETE_ORDER_START:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.START_LOADER:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.STOP_LOADER:
      return {
        ...state,
        loading: false,
      };
    case contractActionType.SHOW_ALERT:
      return {
        ...state,
        alert: {
          severity: payload.severity,
          status: true,
          message: payload.message,
        },
      };
    case contractActionType.HIDE_ALERT:
      return {
        ...state,
        alert: {
          severity: "",
          status: false,
          message: "",
        },
      };
    case contractActionType.POST_UPDATE_ORDER_START:
      return {
        ...state,
        loading: true,
      };
    case contractActionType.POST_UPDATE_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "error",
          status: true,
          message: payload || "Failed to Update Order",
        },
      };
    case contractActionType.POST_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: {
          severity: "success",
          status: true,
          message: "Successfully Updated Order",
        },
      };
    default: {
      return state;
    }
  }
};

export default econtractReducer;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import {
  selectIsUserLoggedIn,
  selectCurrentUser,
  selectTempUser,
  selectIsUserFetching
} from 'redux/user/user.selector';

const PrivateRoute = ({
  isLoggedIn,
  currentUser,
  tempUser,
  loading,
  layout: Layout,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn && !loading) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location }
              }}
            />
          );
        }

        // authorised so return component
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired
};

const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsUserLoggedIn,
  currentUser: selectCurrentUser,
  tempUser: selectTempUser,
  loading: selectIsUserFetching
});

export default connect(mapStateToProps)(PrivateRoute);

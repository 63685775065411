export const customerActionTypes = {
    'FETCH_CUSTOMER_START' : 'FETCH_CUSTOMER_START',
    'FETCH_CUSTOMER_SUCCESS' : 'FETCH_CUSTOMER_SUCCESS',
    'FETCH_CUSTOMER_FAILED' : 'FETCH_CUSTOMER_FAILED',
    'FETCH_CUSTOMERS_START' : 'FETCH_CUSTOMERS_START',
    'FETCH_CUSTOMERS_SUCCESS' : 'FETCH_CUSTOMERS_SUCCESS',
    'FETCH_CUSTOMERS_FAILED' : 'FETCH_CUSTOMERS_FAILED',
    'FETCH_CUSTOMEREXT_START' : 'FETCH_CUSTOMEREXT_START',
    'FETCH_CUSTOMEREXT_SUCCESS' : 'FETCH_CUSTOMEREXT_SUCCESS',
    'FETCH_CUSTOMEREXT_FAILED' : 'FETCH_CUSTOMEREXT_FAILED'
}
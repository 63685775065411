import { statementActionType } from './statement.type';

const INITIAL_STATE = {
    isStatementFetching: false,
    customerStatement: null,
    errorMessage: undefined
}

const customerStatementReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case statementActionType.FETCH_CUSTOMER_STATEMENT_START:
            return {
                ...state,
                isStatementFetching: true
            }
        case statementActionType.FETCH_CUSTOMER_STATEMENT_SUCCESS:
            return {
                ...state,
                customerStatement: action.payload,
                isStatementFetching: false
            }
        case statementActionType.FETCH_CUSTOMER_STATEMENT_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                isStatementFetching: false
            }
    
        default:
            return state;
    }
}

export default customerStatementReducer;